.page-title-header
{
    font-size: 24px;
    font-weight: bold;
}

.column-header-style
{
    font-size: 18px;
    /* border: 1px #ff0000 solid; */
}

.table-header-style
{
    font-size: 16px;
    /* border: 1px #ff0000 solid; */
    cursor: pointer;
    font-weight: bold;
}

.table-header-nowrap
{
    white-space: nowrap;
}

.header-style
{
    text-align:center;
    /* border: 1px #000000 solid; */
}

.left-align-style
{
    text-align:center;
    /* border: 1px #00ff00 solid; */
}

.text-form-element
{
    margin: 0 auto;
    text-align:left;
    /* border: 1px #0000ff solid; */
    min-width: 180px;
    max-width: 180px;
    /* display: inline-block; */
}

.text-form-element-status
{
    margin: 0 auto;
    text-align:left;
    /* border: 1px #0000ff solid; */
    min-width: 110px;
    max-width: 110px;
}

.unset-style
{
    text-align:unset;
}

.cell-center-style
{
    text-align:center;
    /* border: 1px #ff0000 solid; */
}

.cell-center-style-status
{
    text-align:center;
    /* border: 1px #00ff00 solid; */
    margin-top: -15px;
}

#TableAccountResults
{
    margin:0 auto;
    /* visibility:hidden; */
    /* border: 1px #ff0000 solid; */
    width:80%;
}

#TableSPFResults
{
    margin:0 auto;
    /* visibility:hidden; */
}

.table-header
{
    padding:4px;
    text-align:center;
}

#buttonResetFor
{
    margin-top: 5px;
}

#buttonAccountSearch
{
    margin-top: 5px;
}

.data-cell-style
{
    padding:6px;
    vertical-align: middle;
    border-top: 1px #d5d8dc solid;
}

.data-cell-download-style
{
    padding:6px;
    text-align:center;
    vertical-align: middle;
}
.data-table-border
{
    border: 1px #d5d8dc solid;
}

.scrollableAccountList
{
    max-height: 265px;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px #cccccc solid;
}

.left-align-data-cell
{
    text-align: left;
}

.right-align-data-cell
{
    text-align: right;
}

.center-align-data-cell
{
    text-align: center;
}

.alt {
    background-color:#eee;
}

.SPFList
{
    padding-top: 30px;;
}

.center-nav
{
    text-align: right;
    margin: 0 auto;
}

.modalMargin
{
    margin-top: 20px;
}

.selectContractor
{
    width:100px;
}

.modal {
    font-size: 16px;
    display: block !important;
    position: relative !important;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  .arrowUp
  {
    transform: rotate(-90deg);
    margin:5px;
  }

  .arrowDown
  {
    transform: rotate(90deg);
    margin:5px;
  }

  .btn-reset
  {
      margin:5px;
      position: relative;
      top:2px;
  }

  .imgDownload {
      cursor: pointer;
  }
